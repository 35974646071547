import React from "react";
import styles from "./scss/SubHeader.module.scss";

export const SubHeader: React.FC<{ header: string }> = props => (
  <div className={styles.div}>
    <h2 className={styles.h2}>{props.header}</h2>
    <hr className={styles.hr} />
  </div>
);


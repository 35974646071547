import React from "react";
import styles from "./scss/MakeResults.module.scss";
import Phone from "./images/phone.svg";
import Browser from "./images/browser.svg";
import SMS from "./images/sms.svg";
import { Link, useParams } from "react-router-dom";
import { SubHeader } from "./SubHeader";
import Brands from "./Brands.json";
import ChatPlugin from "./lib/ChatPlugin";
import InContactChatPlugin from "./lib/InContactChatPlugin";

import Axios from "axios";

const corsProxy = 'https://cors-anywhere.herokuapp.com/';

const enCheckStatus = "Check Your Recall Status";
const esCheckStatus = "Verifique si su vehículo necesita una reparación";

const enBack = "Back to Search";
const esBack = "Regrese a la búsqueda";

const enHeader = (brand: string) => `${brand} Vehicles under recall:`;
const esHeader = (brand: string) => `Vehículos ${brand} en riesgo:`;

const enNotFound = "Brand not found";
const esNotFound = "Marca no existe";

const enLinkHeader =
  "If your vehicle is on the list, have your VIN ready, and call to schedule your FREE repair.";
const esLinkHeader =
  "Si su vehículo está en la lista, tenga su VIN a mano, y llame para coordinar una cita de reparación.";

const enVisit = "Visit our website";
const esVisit = "Visíte nuestro sitio web";

const enTextFix = "Text FIX to";
const esTextFix = "ENVÍE EL MENSAJE “FX” AL";

export const MakeResults: React.FC<{ lang: "en" | "es" }> = props => {
  const { brand } = useParams<{ brand: string }>();

  const Back = props.lang === "en" ? enBack : esBack;
  const NotFound = props.lang === "en" ? enNotFound : esNotFound;
  const LinkHeader = props.lang === "en" ? enLinkHeader : esLinkHeader;
  const Visit = props.lang === "en" ? enVisit : esVisit;
  const TextFix = props.lang === "en" ? enTextFix : esTextFix;
  const id = Number(brand) - 1;

  const Header = Brands[id]
    ? props.lang === "en"
      ? enHeader(Brands[id].brand)
      : esHeader(Brands[id].brand)
    : NotFound;

  const CheckStatus = props.lang === "en" ? enCheckStatus : esCheckStatus;

  React.useLayoutEffect(() => {
    if (Brands[id].inContact) {
      InContactChatPlugin(document, Brands[id].inContact);
    }
    else {
      if (Brands[id].chatKey) {
        ChatPlugin(document, Brands[id].chatKey);
      }
    }
    return () => {
      //remove chat plugin on page leave
      document.getElementById("cn_chat_container")?.remove();
      document.getElementById("com-carnow-plugin")?.remove();
      document.querySelectorAll(".cn-wid-cont")?.forEach(x => x.remove());
      document.getElementById("com-nice-incontact")?.remove();
      document.querySelectorAll(".ie-div-position-customer-chat")?.forEach(x => x.remove());
    }
  }, [brand])

  return (
    <div className={styles.container}>
      <div id="chat-el"></div>
      <SubHeader header={CheckStatus} />
      <div className={styles.div}>
        <div className={styles.linkWrapper}>
          <Link className={styles.back} to="/">
            <div />
            {Back}
          </Link>
          <a
            className={styles.phone}
            href={`tel:${Brands[id].phone}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              Axios.get(`${corsProxy}https://one.progmxs.com/px`, { params: { id: 1229379, t: 1 } }).then(response => {
                console.log("Phone Number response ", response.status);
              });
            }}
          >
            <img src={Phone} alt="phone" />
            {Brands[id].phone}
          </a>
        </div>
        <h2 className={styles.h2}>{Header}</h2>
        <div className={styles.recallsDiv}>
          {Brands[id] ? (
            <>
              {Brands[id].recalls.map((recall, key) => (
                <p key={key} className={styles.recall}>
                  {recall}
                </p>
              ))}
              <div className={styles.links}>
                <h3>{LinkHeader}</h3>
                <div>
                  <a
                    className={styles.phone}
                    href={`tel:${Brands[id].phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      Axios.get(`${corsProxy}https://one.progmxs.com/px`, { params: { id: 1229379, t: 1 } }).then(response => {
                        console.log("Phone Number response ", response.status);
                      });
                    }}
                  >
                    <img src={Phone} alt="phone" />
                    {Brands[id].phone}
                  </a>

                  {Brands[id].website.map((web, key) => (
                    <a
                      key={key}
                      href={web.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        Axios.get(`${corsProxy}https://one.progmxs.com/px`, { params: { id: 1229388, t: 1 } }).then(response => {
                          console.log("Visit Our Website response ", response.status);
                        });
                      }}
                    >
                      <img src={Browser} alt="browser" />
                      {web.text + Visit}
                    </a>
                  ))}
                  {Brands[id].sms ? (
                    <a
                      className={styles.sms}
                      href={`sms:${Brands[id].phone.replace(/-/g, "")}?&body=FIX`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        Axios.get(`${corsProxy}https://one.progmxs.com/px`, { params: { id: 1229379, t: 1 } }).then(response => {
                          console.log("Phone Number response ", response.status);
                        });
                      }}
                    >
                      <img src={SMS} alt="sms" />
                      {TextFix} {Brands[id].phone}
                    </a>
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

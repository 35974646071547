const ChatPlugin = (el, key) => {
  var id = "com-carnow-plugin"
  var js, cnjs = el.getElementsByTagName('head')[0];
  if (el.getElementById(id)) return;
  js = el.createElement("script");
  js.id = id;
  js.src = "//app.carnow.com/dealers/carnow_plugin.js?key=" + key;
  cnjs.appendChild(js, cnjs);
  js.onload = function () { window.CarNowPlugin.init(); }
};

export default ChatPlugin;
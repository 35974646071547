import * as React from 'react';

import * as calendarIcon from "./images/CalendarIcon.png";
import * as freeIcon from "./images/FREEIcon.png";
import * as oneHourIcon from "./images/OneHourIcon.png";
import * as carCheckmarkIcon from "./images/CarCheckmarkIcon.png";

const enHeader = [
  'About the',
  'Free',
  'Recall Repair'
];
const esHeader = [
  'Sobre La Reparación',
  'Gratis',
  'Del Defecto'
];

const enKeypoint1 = [
  'The recall repair is completed at absolutely',
  'NO COST',
  'to you, and performed by your preferred local dealer'
];
const esKeypoint1 = [
  'La reparación de la bolsa de aire defectuosa es totalmente',
  'GRATIS',
  'y puede hacerse en la agencia preferida de su localidad.'
];

const enKeypoint2 = 'Most repairs can be completed in one hour or less.';
const esKeypoint2 = 'Casi todas las reparaciones pueden hacerse en una hora o menos.';

const enKeypoint3 = [
  'A',
  'FREE',
  'loaner vehicle or other alternate transportation may be available during your recall repair.'
];
const esKeypoint3 = [
  'Durante la reparación podrá solicitar un vehículo prestado',
  'GRATIS',
  'u otra forma disponible de transporte alternativo.'
];

const enKeypoint4 = [
  'To schedule your',
  'FREE',
  'recall repair, please call',
  'or call your brand directly when you',
  'Search by Brand'
];
const esKeypoint4 = [
  'Para coordinar la reparación',
  'GRATIS',
  'de la bolsa de aire defectuosa, llame al',
  'o llame directamente a la agencia cuando busque según',
  'la marca de su vehículo.'
];

const KeyPointsSection = (props: {
  lang: 'en' | 'es'
}) => {
  return (
    <>
      <section className="tile keypoints-tile">
        <div className="container">
          <div className="title-wrapper">
            <h2 className="center uppercase spread-text">
              {props.lang === 'en' ? enHeader[0] : esHeader[0]}
              <strong> {props.lang === 'en' ? enHeader[1] : esHeader[1]} </strong>
              {props.lang === 'en' ? enHeader[2] : esHeader[2]} 
            </h2>
          </div>
          <div className="keypoints-wrapper">
            <div className="keypoint-wrapper">
              <div className="icon-wrapper">
                <img src={freeIcon} />
              </div>
              <div className="text-wrapper">
                {props.lang === 'en' ? enKeypoint1[0] : esKeypoint1[0]}
                <strong> {props.lang === 'en' ? enKeypoint1[1] : esKeypoint1[1] } </strong>
                {props.lang === 'en' ? enKeypoint1[2] : esKeypoint1[2]}
              </div>
            </div>
            <div className="keypoint-wrapper">
              <div className="icon-wrapper">
                <img src={oneHourIcon} />
              </div>
              <div className="text-wrapper">
                {props.lang === 'en' ? enKeypoint2 : esKeypoint2}
              </div>
            </div>
            <div className="keypoint-wrapper">
              <div className="icon-wrapper">
                <img src={carCheckmarkIcon} />
              </div>
              <div className="text-wrapper">
                {props.lang === 'en' ? enKeypoint3[0] : esKeypoint3[0]}
                <strong> {props.lang === 'en' ? enKeypoint3[1] : esKeypoint3[1] } </strong>
                {props.lang === 'en' ? enKeypoint3[2] : esKeypoint3[2]}
              </div>
            </div>
            <div className="keypoint-wrapper">
              <div className="icon-wrapper">
                <img src={calendarIcon} />
              </div>
              <div className="text-wrapper"> 
                {props.lang === 'en' ? enKeypoint4[0] : esKeypoint4[0]}
                <strong> {props.lang === 'en' ? enKeypoint4[1] : esKeypoint4[1] } </strong>
                {props.lang === 'en' ? enKeypoint4[2] : esKeypoint4[2]}&nbsp;
                <a href="tel:18447240295" className="nowrap">1-844-724-0295 </a>&nbsp;
                {props.lang === 'en' ? enKeypoint4[3] : esKeypoint4[3]}&nbsp;
                <a href="/" className="nowrap">{props.lang === 'en' ? enKeypoint4[4] : esKeypoint4[4]}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KeyPointsSection;
import * as React from 'react';
import Button from './Button';
import { Context } from "./index";

import twitterLogo from "./images/twitter-blue.png";

const QuickFactsSection = (props: {
  lang: 'en' | 'es'
}) => {
  const context = React.useContext(Context);

  const enHeaderPart1 = 'Quick Facts About the Largest';
  const esHeaderPart1 = 'Datos De Interés Sobre El Problema De';
  const enHeaderPart2 = 'Automotive Safety Recall in U.S. History';
  const esHeaderPart2 = 'Seguridad Automotriz Más Grande En La Historia De Los EE.UU.';

  const enBtn = "Check for Recalls";
  const esBtn = "Revíse si su vehículo tiene alertas";

  const Facts = [
    {
      en:
        "More than 41 million vehicles in the US have been recalled due to a defective Takata Airbag.",
      es:
        "Más de 41 millones de vehículos en los Estados Unidos han sido alertados para que cambien sus bolsas de aire defectuosas."
    },
    {
      en:
        "Vehicles from model years 2000 to 2018, from 34 different brands have been affected.",
      es:
        "Vehículos del 2000 al 2018, de 34 marcas diferentes, han sido directamente afectados."
    },
    {
      en:
        "A part inside the defective Takata airbags can explode, shooting sharp metal fragments at passengers inside the vehicle. This can occur no matter what causes the airbag to deploy.",
      es:
        "Una parte dentro de las bolsas defectuosas de Takata puede explotar lanzando fragmentos de metal a los pasajeros dentro del vehículo. Esto puede ocurrir sin importar la causa por la cual las bolsas se activen."
    },
    {
      en:
        "Recalled vehicles located in areas of high humidity are at even greater risk.",
      es:
        "Vehículos con bolsas de aire defectuosas de Takata que se localizan en áreas de alta humedad corren el mayor riesgo."
    },
    {
      en:
        "Takata airbag recall repairs are completed at NO COST by your local dealer. Free loaner vehicles or other forms of alternate transportation are available during the repair. Some dealers can also offer FREE mobile repair at your home or office, inquire when scheduling your appointment.",
      es:
        "La reparación de las bolsas defectuosas de Takata se realiza en la agencia autorizada sin costo alguno de su parte."
    }
  ];

  return (
    <>
      <section className="tile quick-facts-tile">
        <div className="container">
          <div className="title-wrapper">
            <h2 className="center uppercase spread-text">{props.lang === 'en' ? enHeaderPart1 : esHeaderPart1 } <br />{props.lang === 'en' ? enHeaderPart2 : esHeaderPart2 }</h2>
          </div>
          <div className="facts-wrapper mb-3">
            <ul>
            {Facts.map((fact, key) => (
              <li key={key}>
                <div className="fact-wrapper">
                  <p>{context.Lang === "en" ? fact.en : fact.es}</p>
                  {/* <Button
                    classes        = "twitter"
                    wrapperClasses = "left"
                    type           = "simple"
                    link           = {`https://twitter.com/intent/tweet?text=${
                      context.Lang === "en" ? fact.en : fact.es
                    } See if your vehicle is on the recall list at www.SafeAirBags.com %23takataairbagrecall`}
                    newTab = {true}
                  >
                    <>
                      <img src={twitterLogo} />
                      <span>Tweet</span>
                    </>
                  </Button> */}
                </div>
              </li>
            ))}
            </ul>
          </div>
          <Button wrapperClasses="mb-2" type="solid" onClick={() => window.scrollTo(0,0)}>{context.Lang === "en" ? enBtn : esBtn}</Button>
        </div>
      </section>
    </>
  );
};

export default QuickFactsSection;
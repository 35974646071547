import React from "react";
import styles from "./scss/DropDown.module.scss";

type IProps = {
  options: string[];
  update: Function;
  placeholder: string;
};

export const Dropdown = (props: IProps) => {
  const [open, setOpen] = React.useState(false);
  const [animating, setAnimating] = React.useState(false);
  const [options, setOptions] = React.useState([
    props.placeholder,
    ...props.options
  ]);
  const [key, setKey] = React.useState(0);
  const div = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setOptions([props.placeholder, ...props.options]);
  }, [props.placeholder, props.options]);

  const toggle = (state: boolean) => {
    setAnimating(true);
    setOpen(state);
    setTimeout(() => {
      setAnimating(false);
    }, 200);
  };

  const Update = (key: number) => {
    props.update(key);
    setKey(key);
    toggle(false);
    div.current!.blur();
  };

  return (
    <>
      <div
        className={styles.dd}
        tabIndex={0}
        onBlur={() => toggle(false)}
        ref={div}
      >
        <div className={styles.header} onClick={() => toggle(!open)}>
          <label className={key === 0 ? styles.select : styles.selected}>
            {options[Number(key)]}
          </label>
          {open ? (
            <div className={styles.up} />
          ) : (
            <div className={styles.down} />
          )}
        </div>
        <div
          className={styles.list}
          style={{
            opacity: open ? 1 : 0,
            zIndex:
              (open && animating) || (!open && animating) || open ? 10 : -10
          }}
        >
          {options.map((opt, key) => (
            <li key={key} className={styles.item} onClick={e => Update(key)}>
              {opt}
            </li>
          ))}
        </div>
      </div>
    </>
  );
};

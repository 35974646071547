import React from "react";
import styles from "./scss/MakeSearch.module.scss";
import { Dropdown } from "./DropDown";
import { useHistory } from "react-router-dom";
import Button from './Button';
import Axios from "axios";

const Brands = [
  "Acura",
  "Audi",
  "BMW",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Daimler Trucks (Sterling Bullet)",
  "Dodge",
  "Dodge RAM",
  "Ferrari",
  "Ford",
  "GMC",
  "Honda",
  "INFINITI",
  "Jaguar",
  "Jeep",
  "Land Rover",
  "Lexus",
  "Lincoln",
  "Mazda",
  "Mercedes-Benz",
  "Mercury",
  "Mitsubishi",
  "Nissan",
  "Pontiac",
  "Saab",
  "Saturn",
  "Scion",
  "Subaru",
  "Tesla",
  "Toyota",
  "Volkswagen"
];

const enHeader = "Search by Brand";
const esHeader = "Búsque por marca de vehículo";

const enSubHeader =
  "Select your vehicle’s make from the dropdown to see a list of models affected by the recall.";
const esSubHeader = "Seleccione la marca de su vehículo en esta lista";

const enPlaceHolder = "Select vehicle brand";
const esPlaceHolder = "Seleccione la marca";

const enBtn = "Check Brand";
const esBtn = "Verifique Por Marca";
const corsProxy = 'https://cors-anywhere.herokuapp.com/';

export const MakeSearch: React.FC<{ lang: "en" | "es" }> = props => {
  const [selected, setSelected] = React.useState(0);
  const history = useHistory();

  const Header = props.lang === "en" ? enHeader : esHeader;
  const SubHeader = props.lang === "en" ? enSubHeader : esSubHeader;
  const Placeholder = props.lang === "en" ? enPlaceHolder : esPlaceHolder;
  const Btn = props.lang === "en" ? enBtn : esBtn;

  const CheckBrand = () => {
    Axios.get(`${corsProxy}https://secure.adnxs.com/px`, {params: {id: 1247874, t: 1}}).then(response => {
      console.log("check brand ", response.status);
    });
    return selected !== 0 && history.push(`/results/brand/${selected}`);
  }
  return (
    <div className={styles.div}>
      <h5 className={styles.h5}>{Header}</h5>
      <p className={`${styles.body} desc-wrapper`}>{SubHeader}</p>
      <Dropdown
        options={Brands}
        update={(key: number) => setSelected(key)}
        placeholder={Placeholder}
      />
      <Button
        type           = "solid"
        onClick        = {CheckBrand}
        disabled       = {selected === 0 ? true : false}
        wrapperClasses = "left pt-2"
      >
        {Btn}
      </Button>
    </div>
  );
};

import * as React from 'react';
import { SearchBoxes } from "./SearchBoxes";
import { Context } from "./index";

const HeroSection = (props: {
  lang: 'en' | 'es'
}) => {

  const enHeader = 'Don\'t Risk Injury or Death';
  const esHeader = [
    'Evite Sufrir Heridas Graves',
    'O La Muerte'
  ];

  const enSubHeader = 'Schedule Free Recall Repair: Call or text "FIX" to';
  const esSubHeader = 'Coordine la reparación gratis de la bolsa de aire defectuosa: Llame o envíe el mensaje "FIX" al';
  
  const enSubHeader2 = 'Check if your vehicle has a defective airbag';
  const esSubHeader2 = 'Verifique si su vehículo tiene una bolsa de aire defectuosa';

  const context = React.useContext(Context);
  return(
    <>
      <section className="tile hero-tile">
        <div className="container">
          <div className="header-wrapper">
  <h1 className="center uppercase spread-text">{props.lang === "en" ? enHeader : <>{`${esHeader[0]}`}<br />{`${esHeader[1]}`}</>}</h1>
            <h2>{props.lang === "en" ? enSubHeader : esSubHeader} <a href="tel:+18447240295" className="accent"><strong>1-844-724-0295</strong></a></h2>
            <h2 className="divider-headline">--{props.lang === 'en' ? 'OR' : 'O'}--</h2>
            <h2>{props.lang === 'en' ? enSubHeader2 : esSubHeader2}</h2>
          </div>
          <div className="search-wrapper">
            <SearchBoxes lang={context.Lang} />
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
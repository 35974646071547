import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "typeface-montserrat";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
//import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
ReactGA.initialize(`${process.env.REACT_APP_GA_TAG}`);
//ReactGA.pageview(window.location.pathname + window.location.search);
//TagManager.initialize({ gtmId: "GTM-N3PHG6N" });

/*---------------Context---------------------------*/
type Context = {
  Lang: "en" | "es";
  SetLang: React.Dispatch<React.SetStateAction<"en" | "es">>;
  CookieDisclosure: boolean;
  ShowCookieDisclosure: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Context = React.createContext<Context>({
  Lang: "en",
  SetLang: () => {},
  CookieDisclosure: true,
  ShowCookieDisclosure: () => {}
});

const ContextWrapper = (props: { children?: ReactNode }) => {
  const [Lang, SetLang] = React.useState<"en" | "es">("en");
  const [CookieDisclosure, ShowCookieDisclosure] = React.useState(true);

  return (
    <Context.Provider
      value={{ Lang, SetLang, CookieDisclosure, ShowCookieDisclosure }}
    >
      {props.children}
    </Context.Provider>
  );
};
/*----------------End-Context-----------------------*/

ReactDOM.render(
  <BrowserRouter>
    <ContextWrapper>
      <App />
    </ContextWrapper>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

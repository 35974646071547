import React from "react";
import styles from "./scss/VINResults.module.scss";
import classNames from "classnames";
import { CheckVIN, RecallInformation, OEM, Recall } from "./lib/Api";
import { SubHeader } from "./SubHeader";
import { Link, useLocation } from "react-router-dom";
import Phone from "./images/phone.svg";
import Browser from "./images/browser.svg";
import SMS from "./images/sms.svg";
import useMedia from "use-media";
import { ClipLoader } from "react-spinners";
import Brands from "./Brands.json";
import ChatPlugin from "./lib/ChatPlugin";
import InContactChatPlugin from "./lib/InContactChatPlugin";

const Warning = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <path
      fill="#EF2B12"
      fillRule="nonzero"
      d="M15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0zm.328 21a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm0-16a2.328 2.328 0 0 0-2.322 2.494l.715 10.01a1.611 1.611 0 0 0 3.214 0l.715-10.01A2.328 2.328 0 0 0 15.328 5z"
    />
  </svg>
);

const CheckMark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <path
      fill="#319C0D"
      fillRule="nonzero"
      d="M15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0zm6.023 9.828h-.015l-7.896 8.277-4.293-3.769a1.155 1.155 0 1 0-1.511 1.746l5.115 4.489a1.154 1.154 0 0 0 1.596-.07l8.673-9.08a1.154 1.154 0 1 0-1.669-1.593z"
    />
  </svg>
);

const enCheckStatus = "Check Your Recall Status";
const esCheckStatus = "Verifique si su vehículo necesita una reparación";

const enBack = "Back to Search";
const esBack = "Regrese a la búsqueda";

const enCheckNHTSA = "Check if your vehicle has other recalls at";
const esCheckNHTSA = "Check if your vehicle has other recalls at";

const NotFound = "VIN not found";
const NoTakata =
  "Great News! Your vehicle doesn’t have any recalls associated with the Takata Airbag recall.";
const TakataFound = (num: number) =>
  `There ${num === 1 ? "is" : "are"} ${num} Takata Airbag recall${num > 1 ? "s" : ""
  } associated with this VIN`;

const enVisit = "Visit our website";
const esVisit = "Visíte nuestro sitio web";

const enTextFix = "Text FIX to";
const esTextFix = "ENVÍE EL MENSAJE “FX” AL";

const useQuery = () => new URLSearchParams(useLocation().search);

export const VINResults: React.FC<{
  lang: "en" | "es";
}> = props => {
  const params = useQuery();
  const mobile = useMedia({ maxWidth: 900 });
  const CheckStatus = props.lang === "en" ? enCheckStatus : esCheckStatus;
  const Back = props.lang === "en" ? enBack : esBack;
  const Visit = props.lang === "en" ? enVisit : esVisit;
  const CheckNHTSA = props.lang === "en" ? enCheckNHTSA : esCheckNHTSA;
  const TextFix = props.lang === "en" ? enTextFix : esTextFix;

  const [recallStatus, setRecallStatus] = React.useState<
    "TakataFound" | "NoTakata" | "NotFound"
  >("NotFound");

  const [recallData, setRecallData] = React.useState<RecallInformation | null>(
    null
  );
  const [OEM, setOEM] = React.useState<OEM[]>([]);
  const [TRecalls, setTRecalls] = React.useState<Recall[]>([]);
  const [Recalls, setRecalls] = React.useState<Recall[]>([]);
  const [requested, setRequested] = React.useState(false);

  React.useEffect(() => {
    setRequested(true);
    CheckVIN(params.get("VIN") || "", params.get("captchaResponse") || "").then(res => {
      setOEM(res.data.oem);
      if (res.data.recallInformation.length !== 0) {
        setRecallData(res.data.recallInformation[0]);
      } else {
        setRecallStatus("NotFound");
      }
      setRequested(false);
    });
  }, []);

  React.useEffect(() => {
    if (recallData !== null) {
      if (recallData!.recallAvailability !== "InvalidVin") {
        if (recallData!.recalls.length === 0) {
          setRecallStatus("NoTakata");
        } else {
          //check for takata
          let takata = recallData.recalls.filter(recall =>
            OEM.some(
              oem =>
                oem.nhtsaId === recall.nhtsaNumber ||
                oem.oemId === recall.nhtsaNumber ||
                oem.oemId === recall.campaignNumber
            )
          );

          let nonTakata = recallData.recalls.filter(recall =>
            OEM.every(
              oem =>
                oem.nhtsaId !== recall.nhtsaNumber &&
                oem.oemId !== recall.nhtsaNumber &&
                oem.oemId !== recall.campaignNumber
            )
          );

          if (takata.length === 0) {
            setRecallStatus("NoTakata");
          } else {
            setRecallStatus("TakataFound");
          }
          setTRecalls(takata);
          setRecalls(nonTakata);
        }
      } else {
        setRecallStatus("NotFound");
      }
    } else {
      setRecallStatus("NotFound");
    }
  }, [recallData, OEM]);

  React.useEffect(() => {
    if (TRecalls.length > 0) {
      setRecallStatus("TakataFound");
    }
  }, [TRecalls]);

  React.useLayoutEffect(() => {
    if (recallData !== null) {
      let brand = Brands.find(
        brand => brand.brand.toUpperCase() === recallData.make
      );

      if (brand && brand.inContact) {
        InContactChatPlugin(document, brand.inContact);
      }
      else {
        if (brand && brand.chatKey) {
          ChatPlugin(document, brand.chatKey);
        }
      }
    }

    return () => {
      //remove chat plugin on page leave
      document.getElementById("cn_chat_container")?.remove();
      document.getElementById("com-carnow-plugin")?.remove();
      document.querySelectorAll(".cn-wid-cont")?.forEach(x => x.remove());
      document.getElementById("com-nice-incontact")?.remove();
      document.querySelectorAll(".ie-div-position-customer-chat")?.forEach(x => x.remove());
    }
  }, [recallData])

  const Links = () => {
    if (recallData !== null) {
      let brand = Brands.find(
        brand => brand.brand.toUpperCase() === recallData.make
      );
      if (brand !== undefined) {
        return (
          <div className={styles.links}>
            <div>
              {brand.brand !== "Ferrari" ? (
                <a
                  className={styles.phone}
                  href={mobile ? `tel:${brand.phone}` : undefined}
                >
                  <img src={Phone} alt="phone" />
                  {brand.phone}
                </a>
              ) : (
                <></>
              )}
              {brand.website.map((web, key) => (
                <a
                  key={key}
                  href={web.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Browser} alt="browser" />
                  {web.text + Visit}
                </a>
              ))}
              {brand.sms ? (
                <a
                  className={styles.sms}
                  href={
                    mobile
                      ? `sms:${brand.phone.replace(/-/g, "")}?&body=FIX`
                      : undefined
                  }
                >
                  <img src={SMS} alt="sms" />
                  {TextFix} {brand.phone}
                </a>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      <SubHeader header={CheckStatus} />
      <div className={styles.content}>
        <Link to="/">
          <div />
          {Back}
        </Link>
        {!requested ? (
          <>
            <div className={styles.infobar}>
              <div className={styles.VIN}>
                <label>VIN:</label>
                <p>{recallData === null ? "" : recallData.vin}</p>
              </div>
              <div className={styles.year}>
                <label>Year:</label>
                <p>{recallData === null ? "" : recallData.year}</p>
              </div>
              <div className={styles.make}>
                <label>Make:</label>
                <p>{recallData === null ? "" : recallData.make}</p>
              </div>
              <div className={styles.model}>
                <label>Model:</label>
                <p>{recallData === null ? "" : recallData.model}</p>
              </div>
            </div>
            <h2
              className={classNames({
                [styles.header]: true,
                [styles.notFound]: recallStatus === "NotFound",
                [styles.NoTakata]: recallStatus === "NoTakata",
                [styles.TakataFound]: recallStatus === "TakataFound"
              })}
            >
              {recallStatus === "NotFound" ? (
                NotFound
              ) : recallStatus === "NoTakata" ? (
                <>
                  {CheckMark}
                  {NoTakata}
                </>
              ) : recallStatus === "TakataFound" ? (
                <>
                  {Warning}
                  {TakataFound(TRecalls.length)}
                </>
              ) : (
                NotFound
              )}
            </h2>
            <div className={styles.recalls}>
              {TRecalls.map((recall, key) => (
                <div key={key} className={styles.recall}>
                  <p className={styles.date}>
                    {new Date(recall.date).toDateString()}
                  </p>
                  <label>
                    NHTSA Recall Number:&nbsp;<p>{recall.nhtsaNumber}</p>
                  </label>
                  <label>
                    Campaign Number:&nbsp;<p>{recall.campaignNumber}</p>
                  </label>
                  <label>
                    Campaign:&nbsp;<p>{recall.campaign}</p>
                  </label>
                  <label>Description:</label>
                  <p>{recall.description}</p>
                  <label>Safety Risk:</label>
                  <p>{recall.safetyRisk}</p>
                  <label>
                    Status:&nbsp;<p>{recall.status}</p>
                  </label>
                  <div className={styles.whatToDo}>
                    <h5>What to Do:</h5>
                    <p>
                      The fix is <b>simple, fast, and FREE</b>
                    </p>
                    <p>
                      Parts are available at your local dealer. Schedule your
                      repair today. Have your VIN ready, and call to schedule
                      your
                      <b> FREE </b>
                      repair.
                    </p>
                    {Links()}
                  </div>
                  <hr />
                </div>
              ))}
              {Recalls.length > 0 ? <h3>Other Unrepaired Recalls</h3> : <></>}
              {Recalls.map((recall, key) => (
                <div key={key} className={styles.recall}>
                  <p className={styles.date}>
                    {new Date(recall.date).toDateString()}
                  </p>
                  <label>Description:</label>
                  <p>{recall.description}</p>
                  <label>Safety Risk:</label>
                  <p>{recall.safetyRisk}</p>
                  <label>
                    NHTSA Recall Number:&nbsp;<p>{recall.nhtsaNumber}</p>
                  </label>
                  <div></div>
                  <hr />
                </div>
              ))}
            </div>
            {recallStatus === "NoTakata" ? (
              <p>
                {CheckNHTSA}{" "}
                <a
                  href="https://www.nhtsa.gov/recalls"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.NHTSA.gov/recalls
                </a>
              </p>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className={styles.loader}>
            <ClipLoader sizeUnit={"rem"} size={10} color={"#171717"} />
          </div>
        )}
      </div>
    </>
  );
};

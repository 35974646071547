const InContactChatPlugin = (el, config) => {
  var id = "com-nice-incontact"
  var js, cnjs = el.getElementsByTagName('head')[0];
  if (el.getElementById(id)) return;
  js = el.createElement("script");
  js.id = id;
  js.src = "//home-c54.nice-incontact.com/inContact/ChatClient/js/embed.min.js";
  cnjs.appendChild(js, cnjs);
  js.onload = function () { window.icPatronChat.init(config); }
};

export default InContactChatPlugin;
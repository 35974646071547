import React from "react";
import styles from "./scss/SearchBoxes.module.scss";
import { VINSearch } from "./VINSearch";
import { MakeSearch } from "./MakeSearch";

import useMedia from "use-media";

export const SearchBoxes: React.FC<{ lang: "en" | "es" }> = props => {
  const vertical = useMedia({ minWidth: 900 });
  return (
    <div className={vertical ? styles.searchBoxes : styles.vertBoxes}>
      <MakeSearch lang={props.lang} />
      <div className={vertical ? styles.separator : styles.vertSeperator}>
        <hr />
        <label style={{ marginLeft: props.lang === "en" ? 0 : "0.5rem" }}>
          {props.lang === "en" ? "OR" : "O"}
        </label>
        <hr />
      </div>
      <VINSearch lang={props.lang} />
    </div>
  );
};

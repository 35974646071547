import React, { ReactNode } from "react";
import styles from "./scss/CardContainer.module.scss";

type IProps = {
  children?: ReactNode;
};

export const CardContainer: React.FC<IProps> = props => (
  <div className={styles.background}>
    <div className={styles.card}>
      <div className={styles.container}>{props.children}</div>
    </div>
  </div>
);

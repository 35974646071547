import React from "react";
import styles from "./scss/VINSearch.module.scss";
import classNames from "classnames";

import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router";
import { VerifyRepCaptcha } from "./lib/Api";
import { Button } from "./Button";

const enHeader = "Search by Vehicle Identification Number (VIN)";
const esHeader = "Busque por número de identificación de vehículo (VIN)";

const enSubHeader = "Enter your VIN to get VIN-specific details on a recall.";
const esSubHeader =
  "Entre su VIN para obtener detalles específicos sobre esta alerta.";

const enPlaceholder = "Enter 17-Digit VIN";
const esPlaceHolder = "Entre su VIN de 17 caracteres";

const enWhere = "Where is my VIN?";
const esWhere = "¿Cómo encuentro mi VIN?";

const enWhereContent = `Look on the lower left of your car's windshield for your
            17-character Vehicle Identification Number. Your VIN is also located
            on your car's registration card, and it may be shown on your
	    insurance card.`;

const esWhereContent = `En la esquina inferior izquierda del parabrisas de su vehículo se encuentra el número de identificación de su vehículo (VIN). Ése número de identificación es una cadena de 17 caracteres alfanuméricos. También lo podrá encontrar en la tarjeta de registro de su vehículo ó en algunas tarjetas de seguro. En último caso, lo encontrará también en el título de propiedad del vehículo.`;

const enBtn = "Check VIN";
const esBtn = "Verifique Por Marca";

export const VINSearch: React.FC<{ lang: "en" | "es" }> = props => {
  const history = useHistory();
  const [VIN, setVIN] = React.useState("");
  const [toolTip, toggleToolTip] = React.useState(false);
  const [animating, setAnimating] = React.useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = React.useState(false);
  const [captchaResponse, setCaptchaResponse] = React.useState("");

  const Header = props.lang === "en" ? enHeader : esHeader;
  const SubHeader = props.lang === "en" ? enSubHeader : esSubHeader;
  const Placeholder = props.lang === "en" ? enPlaceholder : esPlaceHolder;
  const WhereIsMyVIN = props.lang === "en" ? enWhere : esWhere;
  const WhereIsMyVINContent =
    props.lang === "en" ? enWhereContent : esWhereContent;
  const Btn = props.lang === "en" ? enBtn : esBtn;

  const toggle = (state: boolean) => {
    setAnimating(true);
    toggleToolTip(state);
    setTimeout(() => setAnimating(false), 200);
  };

  const handleReCaptcha = (value: string | null) => {
    if (value !== null && value !== undefined && value !== "") {
      setCaptchaResponse(value);

      // VerifyRepCaptcha(value)
      //   .then(res => setRecaptchaVerified(res.data.success))
      //   .catch(err => alert(err));
    }
  };

  const checkVIN = () => {
    if (captchaResponse.length > 0 && VIN.length === 17) {
      history.push(`/results/vin?VIN=${VIN}&captchaResponse=${captchaResponse}`);
    }
  };

  return (
    <div className={styles.div}>
      <h5 className={styles.h5}>{Header}</h5>
      <label className="desc-wrapper">{SubHeader}</label>
      <div className={styles.vin}>
        <input
          type="text"
          value={VIN}
          onChange={e => setVIN(e.target.value)}
          maxLength={17}
          placeholder={Placeholder}
        />
        <label>{VIN.length} / 17</label>
      </div>
      <div tabIndex={0} onBlur={() => toggle(false)} className={`${styles.toolTip} pt-2`}>
        <div
          style={{
            opacity: toolTip ? 1 : 0,
            visibility: !toolTip && !animating ? "hidden" : "visible",
          }}
          className={classNames({
            [styles.toolTipContent]: true,
            [styles.esToolTip]: props.lang === "es"
          })}
        >
          <p>
            <b>{WhereIsMyVIN}</b>
            <br />
            <br />
            {WhereIsMyVINContent}
          </p>
        </div>
        <label onClick={() => toggle(!toolTip)}>{WhereIsMyVIN}</label>
      </div>
      <div className={styles.recaptcha}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || "no key"}
          onChange={handleReCaptcha}
          hl={props.lang}
        />
      </div>
      <Button
        type = "solid"
        disabled={captchaResponse.length === 0 || VIN.length !== 17}
        onClick={checkVIN}
        wrapperClasses = "mb-2"
      >
        {Btn}
      </Button>
      {/* <button
        onClick={checkVIN}
        disabled={!recaptchaVerified}
        className={
          recaptchaVerified && VIN.length === 17
            ? styles.enabled
            : styles.disabled
        }
      >
        {Btn}
      </button> */}
    </div>
  );
};

import * as React from "react";
import { Link } from "react-router-dom";

export interface ButtonProps {
  id?: string;
  color?: "primary" | "secondary" | "white" | "disabled" | "danger";
  type: "solid" | "outline" | "simple";
  classes?: string;
  wrapperClasses?: string;
  // onClick?: (
  //   event:
  //     | React.MouseEvent<HTMLButtonElement, MouseEvent>
  //     | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  // ) => void;
  onClick?: any;
  link?: string;
  newTab?: boolean;
  title?: any;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  const classes = `btn ${props.disabled ? "disabled" : ""} ${
    props.type ? props.type : ""
  } ${props.color ? props.color : "primary"} ${props.classes ? props.classes : ""}`;

  return (
    <>
      <div
        className={`button-wrapper ${
          props.wrapperClasses ? props.wrapperClasses : ""
        }`}
      >
        {props.link ? (
          <Link
            id={props.id}
            to={props.link}
            className={classes}
            target={props.newTab ? "_blank" : ""}
            rel={props.newTab ? "noopener noreferrer" : ""}
            title={props.title ? props.title : ""}
            onClick={props.onClick}            
          >
            {children ? children : null}
          </Link>
        ) : (
          <button
            id={props.id}
            className={classes}
            onClick={props.onClick}
            title={props.title ? props.title : ""}
            disabled={props.disabled ?? false}
          >
            {children ? children : null}
          </button>
        )}
      </div>
    </>
  );
};

export default Button;

import React from "react";
import styles from "./scss/App.module.scss";
import { Switch, Route, useLocation, Redirect } from "react-router";
import { Header } from "./Header";
import { MorganHeader } from "./MorganHeader";
import { SearchBoxes } from "./SearchBoxes";
import { Video } from "./Video";
import { Footer } from "./Footer";
import { CardContainer } from "./CardContainer";
import { CardHeader } from "./CardHeader";
import { MakeResults } from "./MakeResults";
import { QuickFacts } from "./QuickFacts";
import { AboutRepair } from "./AboutRepair";
import { Context } from "./index";
import { Share } from "./Share";
import { VINResults } from "./VINResults";
import { CookieDisclosure } from "./CookieDisclosure";
import HeroSection from "./HeroSection";
import KeyPointsSection from "./KeyPointsSection";
import QuickFactsSection from "./QuickFactsSection";
import VideoSection from "./VideoSection";
import { PrivacyPolicy } from "./PrivacyPolicy";

import "../src/scss/app.scss";

const enCheckStatus = "Check Your Recall Status";
const esCheckStatus = "Verifique si su vehículo necesita una reparación";

export const App: React.FC = () => {
  const context = React.useContext(Context);
  const location = useLocation();

  const CheckStatus = context.Lang === "en" ? enCheckStatus : esCheckStatus;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={styles.app}>
      <Header />
      <Switch location={location}>
        <Route exact path="/">
          <div id="home" className="page">
            <HeroSection lang={context.Lang} />
            <KeyPointsSection lang={context.Lang} />
            {/* <VideoSection lang={context.Lang} /> */}
            <QuickFactsSection lang={context.Lang} />
          </div>
        </Route>
        <Route path="/results/brand/:brand?">
          <CardContainer>
            <MakeResults lang={context.Lang} />
          </CardContainer>
          {/* <Video lang={context.Lang} /> */}
        </Route>
        <Route path="/results/VIN">
          <CardContainer>
            <VINResults lang={context.Lang} />
          </CardContainer>
          {/* <Video lang={context.Lang} /> */}
        </Route>
        {/* <Route exact path="/quickfacts">
          <CardContainer>
            <QuickFacts />
          </CardContainer>
        </Route> */}
        {/* <Route exact path="/aboutrepair">
          <CardContainer>
            <AboutRepair />
          </CardContainer>
        </Route> */}
        {/* <Route exact path="/share">
          <CardContainer>
            <Share />
          </CardContainer>
        </Route> */}
        <Route path='/video' component={() => {
          window.location.href = 'https://www.youtube.com/watch?v=6cv6pHnw3oA';
          return null;
        }} />
        <Route path="/privacy-policy">
          <PrivacyPolicy lang={"en"} />
        </Route>
        <Route path="/privacy-policy-es">
          <PrivacyPolicy lang={"es"} />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer lang={context.Lang} />
      <CookieDisclosure lang={context.Lang} />
    </div>
  );
};

import React from "react";
import styles from "./scss/Footer.module.scss";
import logo from "./images/safeairbags-logo-white.svg";
import YTLogo from "./images/youtube.svg";
import { Link } from "react-router-dom";
import useMedia from "use-media";

const enHome = "Home";
const esHome = "Página principal";

const enQuickFacts = "Quick Facts";
const esQuickFacts = "Datos de interés";

const enAboutRepair = "About Repair";
const esAboutRepair = "Sobre la reparación";

const enShare = "Share";
const esShare = "Comparta";

const enMoreInfo = "More Information";
const esMoreInfo = "Más información";

const enStayConnected = "Stay Connected";
const esStayConnected = "Manténgase al tanto";

const enPrivacyPolicy = "Privacy Policy";
const esPrivacyPolicy = "Aviso de Privacidad";

const enAboutUsUrl = "https://commercial.safeairbags.com/English?aboutus";
const esAboutUs = "https://commercial.safeairbags.com/Spanish?aboutus";

export const Footer: React.FC<{ lang: "en" | "es" }> = props => {
  const mobile = useMedia({ maxWidth: 750 });

  const Home = props.lang === "en" ? enHome : esHome;
  const QuickFacts = props.lang === "en" ? enQuickFacts : esQuickFacts;
  const AboutRepair = props.lang === "en" ? enAboutRepair : esAboutRepair;
  const Share = props.lang === "en" ? enShare : esShare;

  const MoreInfo = props.lang === "en" ? enMoreInfo : esMoreInfo;
  const StayConnected = props.lang === "en" ? enStayConnected : esStayConnected;

  const enDisclaimer = "DISCLAIMER: This website is addressing PSAN inflators only.";
  const esDisclaimer = "ACLARACIÓN: Este sitio web se refiere solamente a los infladores PSAN.";

  const AboutUs = props.lang === "en" ? "About Us" : "Perfil";
  const AboutUsUrl = props.lang === "en" ? enAboutUsUrl : esAboutUs;

  return (
    <div className={styles.div}>
      <div className={styles.content}>
        {mobile ? (
          <></>
        ) : (
          <div className={styles.contactDiv}>
            <img src={logo} alt="Logo" className={styles.logo} />
            <div className="disclaimer-wrapper">
              <p>{props.lang === "en" ? enDisclaimer : esDisclaimer}</p>
            </div>
          </div>
        )}
        <div className={styles.Section}>
          <p>SafeAirBags</p>
          <a
            href={AboutUsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {AboutUs}
          </a>
          <Link to={{ pathname: "/" }}>{Home}</Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.lang === "en" ? "/privacy-policy" : "/privacy-policy-es"}
          >
            {props.lang === "en" ? enPrivacyPolicy : esPrivacyPolicy}
          </a>
        </div>
        <div className={styles.Section}>
          <p>{MoreInfo}</p>
          <a
            href="https://www.nhtsa.gov/equipment/takata-recall-spotlight"
            target="_blank"
            rel="noopener noreferrer"
          >
            NHTSA.gov
          </a>
          <a
            href="https://www.carfax.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Carfax.com
          </a>
          <a
            href="https://www.nhtsa.gov/recalls/takata-air-bags-nadi"
            target="_blank"
            rel="noopener noreferrer"
          >
            Non-Azide Driver Inflator Recall Information
          </a>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import styles from "./scss/Header.module.scss";
import useMedia from "use-media";
import classNames from "classnames";
import logo from "./images/safeairbags-logo.svg";
import hamburger from "./images/hamburger.svg";
import close from "./images/close.svg";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router";
import { Context } from "./index";

const enQuickFacts = "Quick Facts";
const esQuickFacts = "Datos de interés";

const enAboutRepair = "About Repair";
const esAboutRepair = "Sobre la reparación";

const enPhoneHeader = 'Schedule Free Repair';
const esPhoneHeader = 'Coordine La Reparación Gratis';

const enShare = "Share";
const esShare = "Comparta";

export const Header: React.FC = () => {
  const context = React.useContext(Context);
  const collapsed = useMedia({ minWidth: 1100 });
  const [open, toggleOpen] = React.useState(false);
  const [langOpen, toggleLangOpen] = React.useState(false);

  const QuickFacts = context.Lang === "en" ? enQuickFacts : esQuickFacts;
  const AboutRepair = context.Lang === "en" ? enAboutRepair : esAboutRepair;
  const Share = context.Lang === "en" ? enShare : esShare;

  const location = useLocation();

  React.useEffect(() => {
    if (open === false) {
      toggleLangOpen(false);
    }
  }, [open]);

  React.useEffect(() => {
    toggleOpen(false);
  }, [context.Lang]);

  React.useEffect(() => {
    setTimeout(function () {
      let elems = document.querySelectorAll(".Header_menucontainer") as NodeListOf<HTMLElement>;
      for (let i = 0; i < elems.length; i++) {
        elems[i].style.display = (location.pathname.indexOf("privacy-policy") >= 0 ? "none" : "inline-flex");
      }
    }, 0);
  }, [location.pathname]);

  return (
    <div className={styles.headercontainer}>
      <Link to="/" className={styles.logoLink}>
        <img
          src={logo}
          alt="Logo"
          className={styles.logo}
          onClick={() => toggleOpen(false)}
        />
      </Link>
      {!collapsed ? (
        <>
          <div className={styles.menuBtn + " Header_menucontainer"} onClick={() => toggleOpen(!open)}>
            <img alt="menu" src={open ? close : hamburger} />
          </div>
          <div
            tabIndex={0}
            //onBlur={() => toggleOpen(false)}
            className={classNames({
              [styles.menu]: true,
              [styles.openMenu]: open,
              [styles.closedMenu]: !open
            })}
          >
            <div
              onClick={() => toggleLangOpen(!langOpen)}
              className={classNames({
                [styles.item]: true,
                [styles.langMenu]: true,
                [styles.openLang]: langOpen,
                [styles.closedLang]: !langOpen
              })}
            >
              <p className={styles.langHeader}>Language</p>
              <span />
              <div>
                <p
                  className={classNames({
                    [styles.lang]: true,
                    [styles.activeLang]: context.Lang === "en"
                  })}
                  onClick={() => context.SetLang("en")}
                >
                  English
                </p>
                <p
                  className={classNames({
                    [styles.lang]: true,
                    [styles.activeLang]: context.Lang === "es"
                  })}
                  onClick={() => context.SetLang("es")}
                >
                  Espa&ntilde;ol
                </p>
              </div>
            </div>
            {/* <Link
              onClick={() => toggleOpen(false)}
              className={styles.item}
              to="/quickfacts"
            >
              {QuickFacts}
            </Link> */}
            {/* <Link
              onClick={() => toggleOpen(false)}
              className={styles.item}
              to="/aboutrepair"
            >
              {AboutRepair}
            </Link>
            <Link
              onClick={() => toggleOpen(false)}
              className={styles.item}
              to="/share"
            >
              {Share}
            </Link> */}
          </div>
        </>
      ) : (
        <div className={styles.navDiv + " Header_menucontainer"}>
          <div className={styles.langDiv}>
            <button
              className={
                context.Lang === "en" ? styles.activeLang : styles.inactiveLang
              }
              onClick={() => context.SetLang("en")}
            >
              English
            </button>
            <span>&#124;</span>
            <button
              className={
                context.Lang === "es" ? styles.activeLang : styles.inactiveLang
              }
              onClick={() => context.SetLang("es")}
            >
              Espa&ntilde;ol
            </button>
          </div>
          {/* <div className={styles.phoneWrapper}>
            <span className={styles.phoneLabel}>{context.Lang === 'en' ? enPhoneHeader : esPhoneHeader}</span>
            <span className={styles.phone}><a href="tel:+18447240295">1-844-724-0295</a></span>
          </div> */}
          {/* <div className={styles.navLinks}>
            <NavLink
              className={styles.navbutton}
              activeClassName={styles.activenavlink}
              to={{ pathname: "/quickfacts" }}
            >
              {QuickFacts}
            </NavLink>
            <NavLink
              className={styles.navbutton}
              activeClassName={styles.activenavlink}
              to={{ pathname: "/aboutrepair" }}
            >
              {AboutRepair}
            </NavLink>
            <NavLink
              className={styles.navbutton}
              activeClassName={styles.activenavlink}
              to={{ pathname: "/share" }}
            >
              {Share}
            </NavLink>
          </div> */}
        </div>
      )}
    </div>
  );
};

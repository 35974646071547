import Axios from "axios";

const env = process.env.REACT_APP_API_URL === "apiv2" ? "prod" : "dev";

const corsProxy = 'https://cors-anywhere.herokuapp.com/';
const recallUrl = `${process.env.REACT_APP_API_URL}/carfax/recalls`;
// const recallUrl = "https://s2eo9nsti9.execute-api.us-east-2.amazonaws.com/prod/carfax/recalls";
const recaptchaUrl = `${process.env.REACT_APP_API_URL}/carfax/recaptcha`;
// const recaptchaUrl = `https://s2eo9nsti9.execute-api.us-east-2.amazonaws.com/prod/carfax/recaptcha`;


export type CarFaxJson = {
  recallInformation: RecallInformation[];
  oem: OEM[];
};

export type OEM = {
  oemId: number | string;
  oem: string;
  nhtsaId: string;
};

export type RecallInformation = {
  vin: string;
  model: string;
  make: string;
  year: number;
  recallAvailability: string;
  serviceCampaignAvailability: string;
  recallAvailabilityMessage: string;
  recalls: Recall[];
  serviceCampaigns: any[];
};

export type Recall = {
  date: number;
  nhtsaNumber: string;
  campaignNumber: string;
  campaign: string;
  description: string;
  safetyRisk: string;
  status: string;
  remedy: string;
  expirationDate?: Date;
};

type ReCaptcha = { success: boolean; challenge_ts: Date; hostname: string };

export const CheckVIN = (vin: string, captchaResponse: string) => {

  // Axios.get(`${corsProxy}https://one.progmxs.com/px`, {params: {id: 1247875, t: 1}}).then(response => {
  //   console.log("Check Vin response ", response.status);
  // });

  // Axios.get(`${corsProxy}https://secure.adnxs.com/px`, {params: {id: 1247875, t: 1}}).then(response => {
  //   console.log("third response ", response);
  // });


  return Axios.post<CarFaxJson>(recallUrl, { vins: [vin], captchaResponse: captchaResponse });
}

export const VerifyRepCaptcha = (key: string) =>
  Axios.post<ReCaptcha>(recaptchaUrl, { captchaResponse: key });

import React from "react";
import styles from "./scss/CookieDisclosure.module.scss";
import { Context } from "./index";
import collapse from "./images/collapse.svg";
import classNames from "classnames";
import useMedia from "use-media";
import { Button } from "./Button";

const enDisclosureHeader = "Our website’s use of cookies and pixels";
const esDisclosureHeader =
  "Consentimiento para el uso de cookies y píxeles en el sitio web";

const enDisclosure = (
  <p>
    We use cookies and pixels to capture information [which may identify the
    device used to visit this site], which helps us assess and attempt to
    satisfy your needs as a customer or potential customer and to analyze our
    website traffic. We also share information about your use of our site with
    our analytics partners who may combine it with other information that you’ve
    provided to them or that they’ve collected from your use of their services.
    By continuing to use this website, you consent to the use of our cookies and
    pixels in accordance with our{" "}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="/privacy-policy"
    >
      Privacy Notice
    </a>
    . You may control the data being collected from your visit by{" "}
    <a
      href="/privacy-policy#opt-out"
      target="_blank"
      rel="noopener noreferrer"
    >
      opting out
    </a>
    .
  </p>
);
const esDisclosure = (
  <p>
    Usamos cookies y píxeles para capturar información [la cual puede
    identificar el dispositivo utilizado para visitar este sitio], lo que nos
    ayuda a evaluar y a tratar de satisfacer sus necesidades como cliente o
    cliente potencial y a analizar el tráfico de nuestro sitio web. También
    compartimos información sobre su uso de nuestro sitio con nuestros socios de
    analítica de datos quienes pueden combinar esa información con cualquier
    otra que usted les haya dado o que ellos hayan recopilado una vez que usted
    haya usado sus servicios. Si continúa usando este sitio web, usted acepta
    por este medio el uso de nuestras cookies y píxeles de acuerdo con nuestro{" "}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="/privacy-policy-es"
    >
      Aviso de Privacidad
    </a>
    . Puede controlar la información que se recopila en su visita si{" "}
    <a
      href="/privacy-policy-es#opt-out"
      target="_blank"
      rel="noopener noreferrer"
    >
      opta salir del sitio
    </a>
    .
  </p>
);

const enButtonCopy = "I CONSENT";
const esButtonCopy = "ACEPTO";

const enReadMore = "Read more";
const esReadMore = "Lee mas";

export const CookieDisclosure: React.FC<{ lang: "en" | "es" }> = props => {
  const context = React.useContext(Context);
  const mobile = useMedia({ maxWidth: 899 });
  const [showMore, toggleShowMore] = React.useState(false);

  const disclosureHeader =
    props.lang === "en" ? enDisclosureHeader : esDisclosureHeader;
  const disclosure = props.lang === "en" ? enDisclosure : esDisclosure;
  const buttonCopy = props.lang === "en" ? enButtonCopy : esButtonCopy;
  const readMore = props.lang === "en" ? enReadMore : esReadMore;

  const desktopContent = (
    <div className={styles.desktopContent}>
      <div className={styles.copy}>
        <h4 className={props.lang === "en" ? styles.en : styles.es}>
          {disclosureHeader}
        </h4>
        {disclosure}
      </div>
      <div className={styles.consent}>
        <Button
          type = "solid"
          onClick = {() => context.ShowCookieDisclosure(false)}
        >
          {buttonCopy}
        </Button>
        {/* <button onClick={() => context.ShowCookieDisclosure(false)}>
          {buttonCopy}
        </button> */}
      </div>
    </div>
  );

  const closedMobileContent = (
    <div className={styles.closedMobileContent}>
      <div className={styles.copy}>
        <h4>{disclosureHeader}</h4>
        <p className={styles.readMore} onClick={() => toggleShowMore(true)}>
          {readMore}
        </p>
      </div>
      <div className={styles.consent}>
        <Button type = "solid" onClick={() => context.ShowCookieDisclosure(false)}>
          {buttonCopy}
        </Button>
      </div>
    </div>
  );

  const openMobileContent = (
    <div className={styles.openMobileContent}>
      <div className={styles.copy}>
        <h4>{disclosureHeader}</h4>
        <img
          alt="collapse"
          className={styles.collapse}
          src={collapse}
          onClick={() => toggleShowMore(false)}
        />
        {disclosure}
      </div>
      <div className={styles.consent}>
        <Button type = "solid" onClick={() => context.ShowCookieDisclosure(false)}>
          {buttonCopy}
        </Button>
      </div>
    </div>
  );

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.hidden]: !context.CookieDisclosure,
        [styles.mobile]: mobile,
        [styles.open]: mobile && showMore
      })}
    >
      {mobile
        ? showMore
          ? openMobileContent
          : closedMobileContent
        : desktopContent}
    </div>
  );
};
